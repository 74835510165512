import React, { useState, useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"
import { Dialog } from "@blueprintjs/core"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessIframe"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import GeneralHours from "@components/GeneralHours"
import Stars from "../components/SocialReviews/Stars"
import LocationPin from "../images/location-pin.inline.svg"

import { getPostObj, telephoneStrip } from "../utils/utils"

const ContactUsPage = ({ pageContext, location, data }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [activeLocation, setActiveLocation] = useState(null)
  const [isFormLoaded, setIsFormLoaded] = useState(false)
  const locationsRef = useRef(null)
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  const gmbs = data.gmbs.nodes

  const getGMB = (id, array) => {
    let result = null

    const find = array.find(gmb => gmb.data.platformId === id)

    if (find && find.data) {
      result = find.data
    }
    return result
  }

  const locations = data.allLocationsJson.nodes.map(obj => {
    // add gmb data to location objects
    return {
      ...obj,
      gmb: getGMB(obj.locationData.gmbId, gmbs)
    }
  })

  const scrollTo = ref => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: ref.offsetTop - 200,
        behavior: "smooth"
      })
    }
  }

  return (
    <SharedStateProvider>
      <Layout
        className={`contact-us ${language === "es" ? "es" : null}`}
        layoutClass={"contact-fab"}
        language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <span className="menu-spacer"></span>

        <div className="contact-us__hero">
          <div className="contact-us__hero--content">
            <NuvoImage
              useAR
              wrapperClassName="desktop"
              publicId={post.googleMapsBackground.splashPublicId}
            />
            <NuvoImage
              useAR
              wrapperClassName="mobile"
              publicId={post.googleMapsBackground.splashPublicIdMobile}
            />
            <div className="contact-us__hero-headings">
              <h1 onClick={() => scrollTo(locationsRef.current)}>
                {post.heading}
              </h1>
              <h2>{post.contactBody.heading}</h2>
              <p>{post.contactBody.blurb}</p>
            </div>
          </div>
          {language === "en" &&
            locations.map((pin, idx) => (
              <div
                key={pin.locationData.locationName}
                id={pin.locationData.cityName.toLowerCase().replace(" ", "-")}
                className="contact-us__pin">
                <LocationPin />

                <div className="contact-us__pin-info">
                  <h4>{pin.locationData.locationName} Office</h4>
                  <a
                    href={pin.locationData.googlePlaceLink}
                    target="_blank"
                    title={
                      language === "es"
                        ? "obtener las direcciones"
                        : "Get Directions"
                    }>
                    {`${pin.locationData.streetNumber} ${pin.locationData.streetName}`}
                    <br />
                    {`${pin.locationData.cityName}, ${pin.locationData.stateAbbr} ${pin.locationData.cityZip}`}
                  </a>

                  <button
                    aria-label="open form dialog"
                    className="contact-us__button"
                    onClick={() => {
                      setIsDialogOpen(true)
                      setActiveLocation(idx)
                    }}>
                    Make an appointment
                  </button>
                </div>
              </div>
            ))}
        </div>

        <div className="row columns  contact-us__body">
          <div className="column is-2"></div>
          <div className="column">
            <h1 onClick={() => scrollTo(locationsRef.current)}>
              {post.heading}
            </h1>
            <h2>{post.contactBody.heading}</h2>
            <p>{post.contactBody.blurb}</p>
          </div>
          <div className="column is-2"></div>
        </div>

        <div className="row columns">
          <div className="column is-2"></div>
          <div className="column">
            <div ref={locationsRef} className="contact-us__locations grid">
              {locations.map((loc, idx) => (
                <div key={loc.locationData.locationName}>
                  <h3>
                    {language === "es" ? (
                      loc.locationData.locationName
                    ) : (
                      <Link
                        to={`/${loc.pageData.title}`}
                        title={`Learn more about our ${loc.locationData.locationName} office`}>
                        {loc.locationData.locationName}
                      </Link>
                    )}
                  </h3>

                  <div className="contact-us__office">
                    <a
                      href={loc.locationData.googlePlaceLink}
                      target="_blank"
                      title={
                        language === "es"
                          ? "obtener las direcciones"
                          : "Get Directions"
                      }>
                      {`${loc.locationData.streetNumber} ${loc.locationData.streetName}`}
                      <br />
                      {`${loc.locationData.cityName}, ${loc.locationData.stateAbbr} ${loc.locationData.cityZip}`}
                    </a>

                    <div>
                      <h4>{language === "es" ? "Teléfono" : "Phone"}</h4>
                      <a
                        href={`tel:+1${telephoneStrip(loc.locationData.phone)}`}
                        title={
                          language === "es" ? "llamanos" : "Give us a call"
                        }>
                        {loc.locationData.phone}
                      </a>
                    </div>

                    <div>
                      <h4>{language === "es" ? "Horas" : "Hours"}</h4>
                      <GeneralHours
                        language={language}
                        // contact
                        locationHours={loc.locationData.locationHours}
                      />
                    </div>

                    <a
                      href={loc.gmb.url}
                      target="_blank"
                      title={
                        language === "es"
                          ? "Ver nuestras reseñas de Google"
                          : "View our Google reviews"
                      }
                      className="contact-us__review">
                      <h4>
                        Google <span>({loc.gmb.totalReviews})</span>
                      </h4>
                      <div className="contact-us__stars">
                        <Stars {...loc.gmb} />
                        <p>{loc.gmb.rating.toFixed(1)}</p>
                      </div>
                    </a>

                    {language === "en" && (
                      <button
                        aria-label="open form dialog"
                        className="contact-us__button mobile"
                        onClick={() => {
                          setIsDialogOpen(true)
                          setActiveLocation(idx)
                        }}>
                        Make an appointment
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="column is-2"></div>
        </div>

        <Dialog
          className="contact-us__dialog--content"
          portalClassName="contact-us__dialog"
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false)
            setActiveLocation(null)
            setIsFormLoaded(false)
          }}>
          <button
            aria-label="close dialog"
            className="dialog__close"
            onClick={() => {
              setIsDialogOpen(false)
              setActiveLocation(null)
              setIsFormLoaded(false)
            }}>
            <svg
              role="presentation"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none">
              <path
                d="M14 14H10.8935L6.95858 8.68L3.06509 14H0L5.38462 6.86L0.289941 0H3.33432L7.04142 4.98L10.7071 0H13.6272L8.55325 6.76L14 14Z"
                fill="#CACACA"
              />
            </svg>
          </button>
          {activeLocation !== null && (
            <>
              <h3>
                {locations[activeLocation].locationData.locationName} Office
              </h3>
              <h4>Make an Appointment</h4>

              {!isFormLoaded && (
                <div
                  className="dialog__loading"
                  style={{
                    position: "absolute",
                    top: "300px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: "0"
                  }}>
                  <span className="loader"></span>
                </div>
              )}

              <Seamless
                hasLoaded={e => setIsFormLoaded(true)}
                src={
                  locations[activeLocation].pageData.layout_3.formSection.url
                }
              />
            </>
          )}
        </Dialog>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
          splashPublicIdMobile
        }
      }
    }
    dataJson(optionsItem: { eq: "siteVariables" }) {
      mainLocation
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
          splashPublicIdMobile
        }
      }
    }
    gmbs: allSocialJson {
      nodes {
        data {
          code
          platform
          platformId
          rating
          totalReviews
          url
        }
      }
    }
    allLocationsJson(sort: { fields: locationData___order }) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
              locationForm
              url
            }
          }
        }
      }
    }
  }
`

export default ContactUsPage
